div[data-animation='glitch-clip'] {
  --gap-horizontal: '0px';
  animation-timing-function: ease-in-out;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(112, 112, 112, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  height: 0px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #ff0000;
  border: 1px solid #c5c3c3;
}

.prototyping__dashed-line {
  animation: prototyping__dashed 10s linear infinite;
}

.prototyping__blink {
  -webkit-animation: prototyping__blink__animation 1s linear infinite;
  animation: prototyping__blink__animation 1s linear infinite;
}

.prototyping__hide {
  -webkit-animation: prototyping__hide 3s linear infinite;
  animation: prototyping__hide 3s linear infinite;
}

.prototyping__hide._delay_2 {
  -webkit-animation-delay: 2.823s;
  animation-delay: 2.823s;
}
.prototyping__hide._delay_1 {
  -webkit-animation-delay: 3.2627s;
  animation-delay: 3.2627s;
}

.prototyping__text-line {
  width: 15.68px;
  -webkit-animation: prototyping__text-line__animation 4s ease-in-out infinite;
  animation: prototyping__text-line__animation 4s ease-in-out infinite;
}

@keyframes prototyping__dashed {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 50;
  }
}

@keyframes prototyping__hide {
  20% {
    opacity: 0;
  }
}

@keyframes prototyping__blink__animation {
  50% {
    opacity: 0;
  }
}

@keyframes prototyping__text-line__animation {
  0% {
    width: 15.68px;
  }

  25% {
    width: 10px;
  }

  80% {
    width: 4px;
  }

  100% {
    width: 15.68px;
  }
}
